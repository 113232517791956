<template>
    <finance-module-component/>
</template>

<script>
    import FinanceModuleComponent from "@/components/admin/FinanceModuleComponent";

    export default {
        name: "FinanceModule",
        title: "Derivados Financieros | Private",
        components: { FinanceModuleComponent }
    }
</script>

<style scoped>

</style>